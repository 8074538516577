<template>
  <div id="LineAds">
    <header>
      <top-title
      title="LINE 廣告"
      subTitle="">
      </top-title>
    </header>
    <page-main-body
      title="LINE Ads Platform 是什麼"
      subTitle=""
      paragraph1="透過 LINE Ads Platform , 您可以輕鬆在 LINE 通訊軟體內的 Timeline 動態消息、LINE TODAY 及 LINE Points 遞送廣告。未來可能納入更多其他服務，逐漸擴大用戶觸及率及廣告遞送版面。">
    </page-main-body>
    <div class="mobileIn">
      <div class="mobile">
        <div class="tag timeline">Timeline</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/timeline.png">
      </div>
      <div class="mobile">
        <div class="tag lineToday">LINE TODAY</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/lineToday.png">
      </div>
      <div class="mobile">
        <div class="tag linePoint">Line Point</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/linePoint.png">
      </div>
    </div>
    <div class="lineUser">
      <h2>LINE 台灣每月使用者超過 1,900 萬人</h2>
      <h3>LINE 使用者男女比例平均，20-29 歲族群佔比將近 7 成。廣告主可以在 LINE 接觸到 Facebook &amp; Yahoo以外的使用者</h3>
      <div class="charts">
        <div class="userDialog chart">
          <img src="../../../assets/image/service/mediaAds/LineAds/userDialog.png" v-if="!isTablet">
          <img src="../../../assets/image/service/mediaAds/LineAds/userDialogM.png" v-if="isTablet">
        </div>
        <div class="userChart chart">
          <img src="../../../assets/image/service/mediaAds/LineAds/userChart.png">
        </div>
      </div>
    </div>
    <div class="timelineUser">
      <h2>Timeline 動態消息月活耀用戶超過 1,300 萬人</h2>
      <h3>Timeline 動態消息使用者的男女比例平均：男 49%  女 51%</h3>
      <h3>20-49 歲高消費力族群超過7成</h3>
      <div class="charts">
        <div class="chart">
          <img src="../../../assets/image/service/mediaAds/LineAds/timeline1.png">
        </div>
        <div class="chart">
          <img src="../../../assets/image/service/mediaAds/LineAds/timeline2.png">
        </div>
        <div class="chart">
          <img src="../../../assets/image/service/mediaAds/LineAds/timeline3.png">
        </div>
      </div>
    </div>
    <div class="linetodayUser">
      <h2>LINETODAY 高達 1800 萬月活耀用戶，相當於 95% LINE 用戶接觸率</h2>
      <img src="../../../assets/image/service/mediaAds/LineAds/linetodayUser.png">
    </div>
    <div class="linetodayPos">
      <h2>LINE TODAY 廣告刊載位置</h2>
      <img src="../../../assets/image/service/mediaAds/LineAds/lineTodayPos.png">
    </div>
    <div class="lineAdsPlatform">
      <img v-if="!isTablet" class="phone" src="../../../assets/image/service/mediaAds/LineAds/lineAdsPlatform.png">
      <div class="text">
        <div class="title">Line Ads Platform</div>
        <div class="subTitle">成效型原生廣告平台</div>
        <img v-if="isTablet" class="phone" src="../../../assets/image/service/mediaAds/LineAds/lineAdsPlatform.png">
        <div class="details">
          <div class="row people">
            <span>全台超高普及率，可接觸其他投放管到無法觸及的<font>潛在群眾</font></span>
          </div>
          <div class="row smallTrump">
            <span>廣告投放於<font>動態消息牆</font>，可解觸全台 1800 萬 LINE 使用者</span>
          </div>
          <div class="row direction">
            <span>依照不同廣告類型，可導引用戶到<font>指定網頁、觀看影片或進行 App 下載</font></span>
          </div>
        </div>
      </div>
    </div>
    <div class="lineAdvantage">
      <div class="topic lineCustomer">
        <div class="title">LINE 受眾資料廣告遞送</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/lineCustomer.svg">
        <div class="detail">
          年齡 性別<br>地區 興趣
        </div>
      </div>
      <div class="topic passingTarget">
        <div class="title">廣告遞送對象</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/passingTarget.svg">
        <div class="detail">
          iOS OS版本<br>廣告目的<br>APP 安裝 WEB 導流
        </div>
      </div>
      <div class="topic smoothBudget">
        <div class="title">預算平滑</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/smoothBudget.svg">
        <div class="detail">
          自動最佳化廣告遞送
        </div>
      </div>
      <div class="topic automation">
        <div class="title">自動最佳化</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/automation.svg">
        <div class="detail">
          CPC 最佳化<br>CPA 最佳化
        </div>
      </div>
      <div class="topic lookAlike">
        <div class="title">Look Alike 類似受眾</div>
        <img src="../../../assets/image/service/mediaAds/LineAds/lookAlike.svg">
        <div class="detail">
          找到相類似受眾，進行廣告投遞
        </div>
      </div>
    </div>
    <div class="lineInTW">
      <h2>LINE 在台灣</h2>
      <hr>
      <h3>擁有廣大的通訊軟體平台</h3>
      <div class="circles">
        <div class="lineCircle phone">
          <img src="../../../assets/image/service/mediaAds/LineAds/phone.svg">
          <line-percent-circle :goto="91" />
          <font><span>網路使用者</span>會使用 LINE</font>
        </div>
        <div class="lineCircle trumpet">
          <img src="../../../assets/image/service/mediaAds/LineAds/trumpet.svg">
          <line-percent-circle :goto="77" />
          <font>LINE 使用者<span>每週</span>會觀看動態消息</font>
        </div>
        <div class="lineCircle bell">
          <img src="../../../assets/image/service/mediaAds/LineAds/bell.svg">
          <line-percent-circle :goto="62" />
          <font>動態消息使用者<span>每天</span>觀看動態消息</font>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import linePercentCircle from "@/components/linePercentCircle.vue";

export default {
  name: "LineAds",
  components: {
    topTitle,
    pageMainBody,
    linePercentCircle
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$parent.inAdPage = true;
  },
  destroyed() {
    this.$parent.inAdPage = false;
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false
    };
  },
  methods: {
    handleResize() {
      this.isTablet = window.outerWidth <= 768 ? true : false;
      this.isMobile = window.outerWidth <= 480 ? true : false;
    },
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (st > $(".mobileIn").offset().top - hook * 0.7) {
        $(".mobileIn .mobile").each(function(idx) {
          setTimeout(function() {
            $(".mobileIn .mobile img")[idx].classList.add("fadeIn");
          }, 500 * idx);
        });
      }
      if (st > $(".lineUser").offset().top - hook * 0.7) {
        setTimeout(function() {
          $(".userChart img").addClass("popOut");
        }, 0);
        setTimeout(function() {
          $(".userDialog img").addClass("popOut");
        }, 400);
      }
      if (st > $(".timelineUser").offset().top - hook * 0.7) {
        $(".timelineUser .charts .chart").each(function(idx) {
          setTimeout(function() {
            $(".timelineUser .charts .chart img")[idx].classList.add("popOut");
          }, 500 * idx);
        });
      }
      if (st > $(".linetodayUser").offset().top - hook * 0.7) {
        $(".linetodayUser img").addClass("fadeIn");
      }
      if (st > $(".linetodayPos").offset().top - hook * 0.7) {
        $(".linetodayPos img").addClass("fadeIn");
      }
      if (st > $(".phone").offset().top - hook * 0.7) {
        $(".phone").addClass("leftSlideIn");
      }
      if (st > $(".row").offset().top - hook * 0.7) {
        $(".row").each(function(idx) {
          setTimeout(function() {
            $(".row")[idx].classList.add("popOutBefore");
          }, 500 * idx);
        });
      }
      if (st > $(".lineAdvantage").offset().top - hook * 0.7) {
        $(".lineAdvantage .topic").each(function(idx) {
          setTimeout(function() {
            $(".lineAdvantage .topic")[idx].classList.add("fadeIn");
          }, 500 * idx);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../../assets/image/service/mediaAds/LineAds/topBanner.jpg");
}

.popOut {
  transform: scale(1) !important;
  animation: popOut 0.7s;
  @keyframes popOut {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
.fadeIn {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: 0.5s !important;
}
.leftSlideIn {
  transform: translateX(0) !important;
  opacity: 1 !important;
  transition: 0.5s !important;
}

@mixin fontInit {
  h2,
  h3 {
    font-weight: 300;
  }
  h2 {
    @include font(medium);
  }
  h3 {
    @include font(small);
  }
}

#LineAds {
  width: 90vw;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .mobileIn {
    position: relative;
    width: 100%;
    margin: 6% 0;
    display: flex;
    justify-content: space-between;
    .mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        margin-top: 10%;
        transform: translateY(12%);
        opacity: 0;
        transition: 0.5s;
      }
      .tag {
        width: 55%;
        height: 30px;
        padding: 0 5px;
        color: #fff;
        @include center;
        @include font(smaller);
        &.timeline {
          background-color: $mainBlue;
        }
        &.lineToday {
          background-color: $mainPurple;
        }
        &.linePoint {
          background-color: $mainRed;
        }
      }
    }
  }
  .lineUser {
    position: relative;
    width: 100%;
    @include fontInit;
    margin: 6% 0;
    .charts {
      width: 100%;
      margin: 4% 0;
      display: flex;
      justify-content: center;
      .userDialog {
        z-index: 1;
      }
      img {
        width: 100%;
        transform: scale(0);
      }
    }
    @media #{$tablet} {
      .charts {
        flex-direction: column;
      }
    }
  }
  .timelineUser {
    position: relative;
    width: 100%;
    @include fontInit;
    margin: 6% 0;
    .charts {
      width: 100%;
      margin: 4% 0;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      img {
        width: 100%;
        transform: scale(0);
      }
    }
    @media #{$phone} {
      .charts {
        flex-direction: column;
        align-items: center;
        .chart {
          margin: 4% 0;
        }
      }
    }
  }
  .linetodayUser,
  .linetodayPos {
    @include fontInit;
    width: 100%;
    margin: 6% 0;
    img {
      margin: 4% 0;
      width: 100%;
      transform: translateY(12%);
      opacity: 0;
      transition: 0.5s;
    }
  }
  .lineAdsPlatform {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 6% 0;
    .phone {
      margin-right: 16%;
      transform: translateX(-30%);
      opacity: 0;
      transition: 0.3s;
      @media #{$tablet} {
        width: 100%;
        margin-right: 0;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      .title,
      .subTitle {
        font-weight: 700;
        margin: 2% 0;
        @media #{$tablet} {
          padding: 0 10%;
        }
      }
      .title {
        @include font(large);
        color: #349923;
      }
      .subTitle {
        @include font(medium);
        margin-bottom: 10%;
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .row {
          display: flex;
          position: relative;
          margin: 4% 0;
          width: 100%;
          @media #{$tablet} {
            padding: 0 10%;
            width: 75vw;
            margin-left: 3%;
          }
          @include font(small);
          &::before {
            content: "";
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            position: absolute;
            right: 103%;
            top: calc(50% - 25px);
            transform: scale(0);
            @media #{$tablet} {
              top: auto;
              right: auto;
              left: 0;
            }
            @media #{$phone} {
              left: -10px;
              width: 30px;
              height: 30px;
            }
          }
          &.people:before {
            background-image: url("../../../assets/image/service/mediaAds/LineAds/people.svg");
          }
          &.smallTrump:before {
            background-image: url("../../../assets/image/service/mediaAds/LineAds/smallTrump.svg");
          }
          &.direction:before {
            background-image: url("../../../assets/image/service/mediaAds/LineAds/direction.svg");
          }
          &.popOutBefore {
            &::before {
              transform: scale(1) !important;
              animation: popOut 0.7s;
              @keyframes popOut {
                0% {
                  transform: scale(0);
                }
                50% {
                  transform: scale(1.1);
                }
                100% {
                  transform: scale(1);
                }
              }
            }
          }
        }
        font {
          color: #f12379;
        }
      }
    }
  }
  .lineAdvantage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 6% 0;
    .topic {
      width: 22%;
      margin: 0 36px 5% 36px;
      transform: translateY(12%);
      opacity: 0;
      transition: 0.3s;
      .title {
        @include font(small);
      }
      img {
        width: 65%;
        height: 30%;
        margin: 10% 0;
      }
      .detail {
        @include font(smaller);
      }
      @media #{$tablet} {
        margin: 0 12px 10% 12px;
        img {
          width: 50%;
        }
      }
      @media #{$slim} {
        margin: 0 6px 10% 6px;
        .title {
          @include font(smaller);
        }
        img {
          width: 60%;
        }
      }
    }
  }
  .lineInTW {
    position: relative;
    width: 100%;
    margin-bottom: 6%;
    h2,
    h3 {
      font-weight: 300;
    }
    hr {
      height: 1px;
      width: 10%;
      background-color: #707070;
    }
    .circles {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      color: #349923;
      margin: 4% 0;
      .lineCircle {
        position: relative;
        img {
          position: absolute;
          width: 20%;
          height: 20%;
          top: 35%;
          left: 39%;
        }
        font {
          color: #707070;
          span {
            color: #f12379;
          }
        }
      }
    }
  }
}
</style>
