<template>
  <div class="linePCircle">
    <div v-if="!trigger" class="preSapce"></div> 
    <template v-if="trigger">
      <section class="container">
        <figure class="chart" :class="[`goto${goto}`]">
          <svg class="circle" width="200" height="200">
            <circle class="outer" cx="95" cy="95" r="85" transform="rotate(-90, 95, 95)"/>
          </svg>
        </figure>
      </section>
      <div class="underCircle">
        <span :class="[`count${goto}`]">{{goto}}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    goto: Number
  },
  data() {
    return {
      trigger: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (st > $(".linePCircle").offset().top - hook * 0.7) {
        this.trigger = true;
      }
    },
    startCount() {
      setTimeout(() => {
        $(".count" + this.goto).each(function() {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).text()
              },
              {
                duration: 2800,
                easing: "swing",
                step: function(now) {
                  $(this).text(Math.ceil(now));
                }
              }
            );
        });
      }, 100);
    }
  },
  watch: {
    trigger() {
      this.startCount();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

//534才會剛好是填滿整圈 所以 % 數以 534 當 100%去算
$strokeCircle: 534;
.linePCircle {
  position: relative;
  .preSapce {
    height: 500px;
  }
}

.underCircle {
  position: absolute;
  width: 151px;
  height: 151px;
  border: 18.5px solid #b5b5b5;
  border-radius: 100%;
  top: 34px;
  left: 41px;
  z-index: -1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  span {
    transform: translate(10%, -100%);
  }
}

.container {
  padding-top: 14px;
  padding-right: 200px;
  padding-bottom: 200px;
  text-align: center;
  z-index: 1;
}

.chart {
  position: relative;
  display: inline-block;
  color: #999;
  font-size: 19px;
  text-align: center;
  figcaption {
    padding: 50px 25px;
    width: 100px;
    height: 50px;
    border: 20px solid #f0f0f0;
    border-radius: 100px;
    line-height: 50px;
  }
  img {
    position: absolute;
    max-width: 100px;
    max-height: 100px;
    background: white;
  }
}

.circle .outer {
  stroke: #349923;
}

.chart svg {
  position: absolute;
  top: 0;
  left: 0;
}

.outer {
  fill: transparent;
  stroke: #333;
  stroke-width: 19;
  stroke-dasharray: 534;
  transition: stroke-dashoffset 1s;
  opacity: 0;
  -webkit-animation-play-state: running;
}

@mixin circleTo($percentage) {
  .goto#{$percentage} .outer {
    opacity: 1;
    stroke-dashoffset: $strokeCircle - $strokeCircle * ($percentage * 0.01);
    animation: show#{$percentage} 3s;
  }

  @keyframes show#{$percentage} {
    from {
      stroke-dashoffset: $strokeCircle;
    }
    to {
      stroke-dashoffset: $strokeCircle - $strokeCircle * ($percentage * 0.01);
    }
  }
}
@for $i from 1 to 101 {
  @include circleTo($i);
}
</style>
